import Card from "./card"
import Divider from "./divider"

const InfoCard = ({ title, className, info, href, linkClass, newTab, children }) => (
    <Card className={`${className} mx-auto max-w-lg`}>
        <div>

            {title &&

                <>
                    <div className="text-sm px-4 py-2">
                        {title}
                    </div>

                    <Divider />
                </>
            }
            
            <div className="font-bold px-4 py-2">
                {children}
                {!children &&
                    <a href={href} className={`${linkClass} hover:underline`} target={newTab ? "_blank" : ""} >
                        {info}
                    </a> 
                }
            </div>

        </div>
    </Card>
)

export default InfoCard

