import { useState } from 'preact/hooks';

const TWILIO_URL = "https://west-marin-pharmacy-1812.twil.io/send-sms"
const SECRET = "i_am_a_secret_message";
// const TO_PHONE_NUMBER = "+16287779565"
const TO_PHONE_NUMBER = "+14156093276"

const formatData = (dataObj) => {

    let dataStr = ""
    
    for (const key in dataObj) {

        const value = dataObj[key]
        const line = `${key}: ${value}`

        dataStr += line + "\n";
    }

    return dataStr.slice(0, -2)
}

export const useSendText = () => {

    const [sendTextStatus, setSendTextStatus] = useState("unsent")

    const sendText = async (data) => {

        // alert("start")

        setSendTextStatus("sending")

        const body = JSON.stringify({
            Secret: SECRET,
            To: TO_PHONE_NUMBER,
            Body: formatData(data)
        })

        // alert(body)

        // const sleep = ms => new Promise(r => setTimeout(r, ms))
        // await sleep(3000)
        // setSendTextStatus("sent")

        // alert("end")

        const response = await fetch(TWILIO_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: body
        })

        // alert(JSON.stringify(response))

        setSendTextStatus(response.ok ? "sent" : "failed")
    }

    return { sendText, sendTextStatus }
}

