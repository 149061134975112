import { h } from 'preact';
import { Router } from 'preact-router';

import Header from './header';
import Layout from './layout/layout';
import Footer from './footer';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Contact from '../routes/contact';
import About from '../routes/about';
import Manage from '../routes/manage';
import Team from '../routes/team';
import Services from '../routes/services';
import PrivacyPolicy from '../routes/privacy';

import FillPrescription from '../routes/manage/fillPrescription';
import TravelMedication from '../routes/manage/travelMedication';
import Contraception from '../routes/manage/contraception';
import Redirect from './navigation/redirect';
import business from '../../business';
import BackgroundImage from './layout/backgroundImage';

const App = () => {

	const OTHER_LINKS = [
		{
			url: `/`,
		},
		{
			url: `/privacy-policy`,
		},
		{
			url: `/my-pharmacy/travel-medication`,
		},
		{
			url: `/my-pharmacy/hormonal-contraception`,
		},
	]

	return (
		<>
			<BackgroundImage />
			<div id="app" className='flex flex-col fixed top-0 bottom-0 left-0 right-0 overflow-y-scroll overflow-x-hidden'>
				<Header />
				<Layout>
					<Router>
						<Home path="/" />
						<About path="/about-us" />
						<Contact path="/contact-us" />
						{/* <Team path="/our-team" /> */}
						<FillPrescription path="/refill-prescription" />
						<Services path="/services" />
						<PrivacyPolicy path="/privacy-policy" />

						<Manage path="/my-pharmacy" />

						{/* <FillPrescription path="/my-pharmacy/fill-prescription" /> */}
						<TravelMedication path="/my-pharmacy/travel-medication" />
						<Contraception path="/my-pharmacy/hormonal-contraception" />

						{/* <Profile path="/profile/" user="me" />
						<Profile path="/profile/:user" /> */}

						{/* {[...business.links, ...OTHER_LINKS].map((link) => (
							<Redirect path={`${link.url}`} to={`/west-marin-pharmacy${link.url}`} />
						))} */}

					</Router>
				</Layout>
				<Footer />
			</div>
		</>
	)
}

export default App;
