import { h } from 'preact';
import { Link } from 'preact-router/match';
import Divider from '../general/divider'
import InfoCards from './infoCard.js'
import FooterLinks from './footerLinks';
import info from './info';
import business from '../../../business';
import FacebookLink from './facebookLink';

const HOME_LINK = {
	text: `Home`,
	url: `/`,
}

const PRIVACY_LINK = {
	text: `Privacy Policy`,
	url: `/privacy-policy`,
}

const Footer = () => (
	<footer className="">

		<Divider className="" />

		{/* <InfoCards className='' />

		<Divider className='' /> */}
		
		<FooterLinks links={[HOME_LINK, ...business.links, PRIVACY_LINK]} className="bg-white" />
		
		<Divider className="" />

		<FacebookLink link={business.facebookLink} />

	</footer>
);

export default Footer;

