import Input from "../general/input"
import Textarea from "../general/textarea"
import FormCard from "./formCard"
import { ContactInputs } from "./inputCollections"
import { useForm } from "react-hook-form";
import RadioInput from "../general/radioInput";

const ContraceptionForm = ({ className }) => {

    const { register, watch, formState: { errors }, handleSubmit } = useForm();

    const YES_NO_QUESTION_LABELS = [
        "Have you ever taken birth control pills, or used birth control patch, ring, or shot/injection?",
        "Did you ever experience a bad reaction to using hormonal birth control?",
        "Are you currently using birth control pills, or a birth control patch, ring, or shot/injection?",
        "Have you ever been told by a medical professional not to take hormones?",
        "Do you smoke cigarettes?",
        "Do you think you might be pregnant now?",
        "Have you given birth within the past 6 week?",
        "Are you currently beastfeeding an infant you is less than 1 month of age?",
        "Do you have diabete?",
        "Do you get migraine headaches, or headaches so bad that you feel sick to your stomach, you lose the ability to see, it makes it hard to be in the light, or it involves numbness?",
        "Do you have high blood pressure, hypertension, or high cholesterol?",
        "Have you ever had a heart attack or stroke, or been told you had any heart disease?",
        "Have you ever had a blood clot in your leg or in your lung?",
        "Have you ever been told by a medical professional that you are at a high risk of developing a blood clot in your leg or in your lung?",
        "Have you had bariatric surgery or stomach reduction surgery?",
        "Have you had recent major surgery or are you panning to have surgery in the next 4 weeks?",
        "Do you have or have you had breast cancer?",
        "Do you have hepatitis, liver disease, liver cancer, or gall bladder disease, or do you have jaundice (yellow skin or eyes)?",
        "Do you have lupus, rheumatoid arthritis, or any blood disorders?",
    ]

    const isYes = (val) => {
        return val ? (val.toLowerCase().trim() == "yes" ? true : false) : false
    }

    const shouldShow = (questionIndex) => {

        if ([1, 2, 3].includes(questionIndex)) {
            return isYes(watch()[YES_NO_QUESTION_LABELS[0]]) ? true : false
        }

        return true
    }

    return (
        <FormCard handleSubmit={handleSubmit} className={className}>

            <ContactInputs
                errors={errors}
                register={register}
            />

            <Input
                label="What was the first date of your last menstrual period?"
                name="last-period-date"
                type="date"
                requirements={{}}
                required
                register={register}
                errors={errors}
            />

            {YES_NO_QUESTION_LABELS.map((label, index) => (
                <>
                    {shouldShow(index) &&
                        <RadioInput
                            label={label}
                            name={label.toLowerCase().replaceAll(' ', '-')}
                            isYesNo
                            required
                            register={register}
                            errors={errors}
                        />
                    }
                </>
            ))}

            <Textarea
                label="Do you take medication for seizures, tuberculosis (TB), fungal infections, or human immunodeficiency virus (HIV)?"
                name="medication-info"
                register={register}
                requirements={{
                    maxLength: 1000,
                }}
                errors={errors}
            />

            <Textarea
                label="Do you have any other medical problems or take regular medication?"
                name="other-info"
                register={register}
                requirements={{
                    maxLength: 1000,
                }}
                errors={errors}
            />

        </FormCard>
    )
}

export default ContraceptionForm

