import TravelMedicationForm from "../../../components/forms/travelMedicationForm"
import Titling from "../../home/titling"
import info from './info.js'

const TravelMedication = () => (
    <main>
        
        <Titling
            title={info.title}
            subtitle={info.subtitle}
        />

        <TravelMedicationForm className="mt-8" />
        
    </main>
)

export default TravelMedication

