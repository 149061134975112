
const FacebookLink = ({ className, link }) => (
    <div className="p-2 flex items-center justify-center bg-white">
        <span className="text-sm text-gray-500">
            check us out on
        </span>
        &nbsp;
        <a href={link} className="hover:underline font-bold text-blue-400">
            Facebook
        </a>
    </div>
)

export default FacebookLink

