import { Link } from "preact-router/match"
import { MyLink } from "../navigation/myLink"

const HeaderLinks = ({ className, links }) => (
    <div className={`${className}`}>
        {links.map((link, index) => (
            <HeaderLink
                text={link.text}
                url={link.url}
                isLast={index == links.length - 1}
            ></HeaderLink>
        ))}
    </div>
)

// 			<Link className='mx-8 hover:underline' href="/about-us">About Us</Link>
{/* <Link className='hover:underline' href="/contact-us">Contact Us</Link> */}

const HeaderLink = ({ text, url, isLast }) => {

    return (
        <MyLink href={url} className={`hover:underline ${isLast ? "ml-4" :  "mx-4"}`}>
            {text}
        </MyLink>
    )
}

export default HeaderLinks

