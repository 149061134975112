import { EmailInput, FirstNameInput, LastNameInput, PhoneNumberInput, StateInput, StreetAddressInput, ZipCodeInput } from "./inputs"

const FullNameInputs = (props) => {
    // <div className={`${className} flex justify-between`}>
    //     <FirstNameInput className="grow mr-2" />
    //     <LastNameInput className="mb-0 grow ml-2" />
    // </div>
    const { className, ...otherProps } = props
    return (
        <div className={className}>
            <FirstNameInput required {...otherProps} />
            <LastNameInput required {...otherProps} />
        </div>
    )
}

const ContactInputs = (props) => {
    const { className, ...otherProps } = props
    return (
        <div className={className}>
            <FullNameInputs {...otherProps} />
            <PhoneNumberInput required {...otherProps} />
            <EmailInput {...otherProps} />
        </div>
    )
}

const AddressInputs = (props) => {
    const { className, ...otherProps } = props
    return (
        <div className={className}>
            <StreetAddressInput required {...otherProps} />
            <StateInput required {...otherProps} />
            <ZipCodeInput required {...otherProps} />
        </div>
    )
}

export { FullNameInputs, ContactInputs, AddressInputs }

