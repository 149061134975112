import { Link } from "preact-router/match"
import { useState } from 'preact/hooks';
import { CloseSvg, MenuSvg } from "../general/svgs";
import { MyLink } from "../navigation/myLink";

const HeaderLinksMobile = ({ className, links }) => {

    const [showPopup, setShowPopup] = useState(false)

    return (
        <div className={`${className}`}>
            
            <MenuButton className="" onOpen={() => setShowPopup(true)} />

            {showPopup &&
                <LinksPopup links={links} onClose={() => setShowPopup(false)} />
            }

        </div>
    )
}

// const MenuButton = ({ onOpen }) => (
//     <button onClick={onOpen} className="rounded-md text-sm font-bold flex items-center justify-center hover:underline">
//         Menu
//     </button>
// )

const MenuButton = ({ onOpen }) => (
    <button onClick={onOpen} className="flex items-center justify-center">
        <MenuSvg />
    </button>
)

const LinksPopup = ({ links, onClose }) => (
    <div className="fixed z-50 top-0 left-0 right-0 bottom-0 bg-white right-0 p-2 flex items-center justify-center flex-col">
        <div className="flex items-center flex-wrap justify-center">
            {links.map((link, index) => (
                <HeaderLink
                    text={link.text}
                    url={link.url}
                    isLast={index == links.length - 1}
                    onClick={onClose}
                ></HeaderLink>
            ))}
        </div>
        <CloseButton onClose={onClose} />
    </div>
)

const HeaderLink = ({ text, url, onClick }) => (
    <MyLink href={url} onClick={onClick} className={`rounded-md px-2 py-1 m-2 bg-yellow-300 hover:bg-yellow-400 transition-all hover:py-2`}>
        {text}
    </MyLink>
)

// const CloseButton = ({ onClose }) => (
//     <button onClick={onClose} className="rounded-md text-sm font-bold flex items-center justify-center absolute right-4 top-2.5 hover:underline">
//         Back
//     </button>
// )

const CloseButton = ({ onClose }) => (
    <button onClick={onClose} className="flex items-center justify-center absolute right-4 top-2.5">
        <CloseSvg />
    </button>
)
export default HeaderLinksMobile

