import business from "../../../business"

const BLUR = '3px'
const OPACITY = '20%'
const GRAYSCALE = '50%'

const style = {
    background: `url(${business.backgroundImage}) no-repeat center`,
    backgroundSize: 'cover',
    filter: `blur(${BLUR}) opacity(${OPACITY}) grayscale(${GRAYSCALE}) brightness(1.1) contrast(110%)`,
    // "-webkit-filter": 'blur(1px)',
    height: '100%',
}

const BackgroundImage = () => (
    <div style={style}></div>
)

export default BackgroundImage

