import business from "../../../business"

const InfoCards = ({ className }) => (
    <div className={`${className} flex flex-row items-center justify-between bg-white`}>

        <InfoCard>
            <div className="whitespace-pre-wrap">
                {business.address.trim()}
            </div>
        </InfoCard>

        <InfoCard>
            <div className="">
                {business.phoneNumber}
            </div>
        </InfoCard>

        <InfoCard>
            <div className="">
                {business.email}
            </div>
        </InfoCard>

    </div>
)

const InfoCard = ({ children, className }) => (
    <div className={`${className} rounded-md py-1 px-2 m-2`}>
        
        {children}

    </div>
)

export default InfoCards

