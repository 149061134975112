// const Card = ({ className, children }) => (
//     // <section className={`${className} max-w-lg bg-white rounded-lg shadow-md mx-auto`}>
//     <section className={`bg-white border-4 border-yellow-300 shadow-lg rounded-lg ${className}`}>
//         {children}
//     </section>
// )

const Card = ({ className, innerClassName, children }) => (
    <section className={`z-10 shadow-lg rounded-lg p-2 bg-yellow-200 bg-opacity-50 ${className}`} style={{ backdropFilter: 'blur(10px)' }}>
        <div className={`bg-white rounded-lg ${innerClassName}`}>
            {children}
        </div>
    </section>
)

export default Card

