import ContraceptionForm from "../../../components/forms/contraceptionForm"
import FillPrescriptionForm from "../../../components/forms/fillPrescriptionForm"
import WaitTimeCard from "../../../components/forms/waitTimeCard"
import InfoCard from "../../../components/general/infoCard"
import Titling from "../../home/titling"
import info from './info.js'

const Contraception = () => (
    <main>
        
        <Titling
            title={info.title}
            subtitle={info.subtitle}
        />

        <ContraceptionForm className="mt-8" />
        
    </main>
)

export default Contraception

