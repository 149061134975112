import Input from "../general/input"

const FirstNameInput = (props) => (
    <Input
        label="First name"
        name="first-name"
        autoComplete="given-name"
        requirements={{
            maxLength: 50,
        }}
        {...props}
    />
)

const LastNameInput = (props) => (
    <Input
        label="Last name"
        name="last-name"
        autoComplete="family-name"
        requirements={{
            maxLength: 50,
        }}
        {...props}
    />
)

const EmailInput = (props) => (
    <Input
        label="Email address"
        name="email-address"
        autoComplete="email"
        type="email"
        requirements={{
            maxLength: 50,
        }}
        {...props}
    />
)

const PhoneNumberInput = (props) => (
    <Input
        label="Phone number"
        name="phone-number"
        autoComplete="tel"
        type="tel"
        requirements={{
            maxLength: 20,
            minLength: 5,
            pattern: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        }}
        {...props}
    />
)

const BirthdayInput = (props) => (
    <Input
        label="Birthday"
        name="birthday"
        autoComplete="bday"
        type="date"
        required
        requirements={{

        }}
        {...props}
    />
)

const StreetAddressInput = (props) => (
    <Input
        label="Street address"
        name="street-address"
        autoComplete="street-address"
        requirements={{

        }}
        {...props}
    />
)

const StateInput = (props) => (
    <Input
        label="State / Province"
        name="region"
        autoComplete="address-level1"
        requirements={{

        }}
        {...props}
    />
)

const ZipCodeInput = (props) => (
    <Input
        label="ZIP / Postal code"
        name="postal-code"
        autoComplete="postal-code"
        requirements={{

        }}
        {...props}
    />
)

export { FirstNameInput, LastNameInput, EmailInput, PhoneNumberInput, BirthdayInput, StreetAddressInput, StateInput, ZipCodeInput }

