const Titling = ({ className, title, subtitle }) => (
    // <div className={`${className}`} style={{ backdropFilter: 'blur(10px)'}}>
    <div className={className}>
        <h1 className="text-5xl font-bold text-center">
			{title}
        </h1>
        <h2 className='text-gray-500 mt-2 text-xl font-medium text-center'>
            {subtitle}
        </h2>
    </div>
)

export default Titling

