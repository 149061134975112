
const RadioInput = ({ labels, name, label, className, required, register, requirements, errors, isYesNo }) => {
    
    const YES_NO_LABELS = ["Yes", "No"]
    
    return (
        <div className={`flex flex-col mb-3 ${className}`}>

            <div className="text-sm mb-2">
                {label}<span className="text-xs text-gray-700">{required ? "" : " (optional)"}</span>
            </div>

            {(isYesNo ? YES_NO_LABELS : labels).map((_label, index) => (

                <div className="flex flex-row items-center mb-1">

                    <input
                        id={name + index}
                        type={"radio"}
                        name={"name"}
                        className="border-0 focus:outline-blue-300 ml-4"
                        required={required}
                        {...register(label, {
                            required: {
                                value: required,
                                // message: `${label} is required.`
                                message: `Entry is required.`
                            },
                            ...requirements,
                        })}
                        value={_label}
                    />

                    <label htmlFor={name + index} className="text-sm ml-1 font-medium">
                        {_label}
                    </label>
                    
                </div>
            ))}

            {errors[label] &&
                <div className="text-red-500 text-xs font-medium mt-1">
                    {/* {errors[label].message || `Invalid ${label.toLowerCase()}.`} */}
                    {errors[label].message || `Invalid entry.`}
                </div>
            }

        </div>
    )
}

export default RadioInput



//   <input type="radio" id="html" name="fav_language" value="HTML">
//   <label for="html">HTML</label><br>
//   <input type="radio" id="css" name="fav_language" value="CSS">
//   <label for="css">CSS</label><br>
//   <input type="radio" id="javascript" name="fav_language" value="JavaScript"></input>

