import { useSendText } from "../../hooks/useSendText"
import SubmitButton from "./submitButton"
import WaitTimeCard from "./waitTimeCard"

const FormCard = ({ children, className, handleSubmit, onSubmit, buttonTitle, hideWaitTime, waitTime }) => {

    const { sendText, sendTextStatus } = useSendText();

    return (
        <div className={`mx-auto max-w-2xl ${className}`}>

            <form onSubmit={handleSubmit(onSubmit || sendText)} className="px-4 pt-3 bg-blue-300 bg-opacity-50 shadow-lg rounded-lg" style={{ backdropFilter: 'blur(10px)' }}>
            {/* <form onSubmit={handleSubmit(onSubmit)} className={`${className} max-w-2xl mx-auto bg-blue-100 border-blue-300 border-4 shadow-md px-4 pt-3 rounded-lg`}> */}

                {children}

                {/* <div className="text-3xl font-bold">
                    STATUS: {sendTextStatus}
                </div> */}

                <div className="flex pb-4 pt-1">

                    <SubmitButton 
                        title={buttonTitle}
                        sendTextStatus={sendTextStatus}
                        className="w-full"
                    />
                    
                </div>

            </form>

            {!hideWaitTime &&
                <WaitTimeCard waitTime={waitTime} className="mt-8" />
            }

        </div>
    )
}


export default FormCard

