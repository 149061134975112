import Input from "../general/input"
import Textarea from "../general/textarea"
import FormCard from "./formCard"
import { AddressInputs, ContactInputs } from "./inputCollections"
import { useForm } from "react-hook-form";
import { BirthdayInput } from "./inputs";

const TravelMedicationForm = ({ className }) => {

    const { register, formState: { errors }, handleSubmit } = useForm();

    return (
        <FormCard buttonTitle="Send Information" handleSubmit={handleSubmit} className={className}>

            <ContactInputs errors={errors} register={register} />

            <AddressInputs errors={errors} register={register} />

            <Input
                label="Start date"
                name="start-date"
                type="date"
                required
                requirements={{
                    
                }}
                errors={errors}
                register={register}
            />

            <Input
                label="End date"
                name="end-date"
                type="date"
                register={register}
                required
                requirements={{
                    
                }}
                errors={errors}
            />

            <Textarea
                label="Where are you going?"
                name="where-are-you-going"
                register={register}
                required
                requirements={{
                    maxLength: 500,
                }}
                errors={errors}
                small
            />

            <Textarea
                label="What is your current insurance?"
                name="current-insurance"
                register={register}
                required
                requirements={{
                    maxLength: 500,
                }}
                errors={errors}
                small
            />

            <Textarea
                label="What are your current medications?"
                name="current-medications"
                register={register}
                required
                requirements={{
                    maxLength: 1000,
                }}
                errors={errors}
            />

            <Textarea
                label="Do you have any allergies?"
                name="allergies"
                register={register}
                required
                requirements={{
                    maxLength: 1000,
                }}
                errors={errors}
            />

        </FormCard>
    )
}

export default TravelMedicationForm

