import { useEffect, useState } from "preact/hooks"

const SubmitButton = ({ className, title, sendTextStatus }) => {

    const [buttonText, setButtonText] = useState(sendTextStatus)
    const [isEnabled, setIsEnabled] = useState(true)

    useEffect(() => {

        switch(sendTextStatus) {
            case "sending":
                setButtonText("Sending...")
                setIsEnabled(false)
                break;
            case "sent":
                setButtonText("Success!")
                setIsEnabled(false)
                break;
            case "failed":
                setButtonText("An error occured, try again?")
                setIsEnabled(true)
                break;
            case "unsent":
            default:
                setButtonText(title ? title : "Submit")
                setIsEnabled(true)
        }

    }, [sendTextStatus])

    const enabledClass = "bg-blue-500 hover:bg-blue-600 shadow-lg"
    const disabledClass = "bg-blue-300"

    return (
        <button type="submit" disabled={!isEnabled} className={`${className} ${isEnabled ? enabledClass : disabledClass} rounded-lg text-white transition-all px-3 py-2 font-medium`}>
            {buttonText}
        </button>
    )
}

export default SubmitButton

