// import { useState } from 'preact/hooks';

const Textarea = ({ label, name, placeholder, className, required, register, requirements, errors, small }) => {

    return (
        <div className={`flex flex-col mb-4 ${className}`}>
            <label htmlFor={name} className="text-sm mb-1">
                {label}&nbsp;&nbsp;<span className="text-xs text-gray-700">{required ? "" : " (optional)"}</span>
            </label>
            <textarea
                id={name}
                name={name}
                className="py-1 px-2 rounded-md border-0 shadow-md focus:outline-blue-300 resize-none"
                rows={small ? 4 : 8}
                placeholder={placeholder}
                required={required}
                {...register(label, {
                    required: {
                        value: required,
                        // message: `${label} is required.`
                        message: `Entry is required.`
                    },
                    ...requirements,
                })}
            />
            {errors[label] &&
                <div className="text-red-500 text-xs font-medium mt-1">
                    {/* {errors[label].message || `Invalid ${label.toLowerCase()}.`} */}
                    {errors[label].message || `Invalid entry.`}
                </div>
            }
        </div>
    )
}

export default Textarea

