import { Link } from "preact-router/match"

export const MyLink = (props) => {

    // const URL_PREFIX = "/west-marin-pharmacy"
    const URL_PREFIX = ""

    const { href, ...otherProps } = props

    return (
        <Link href={href ? `${URL_PREFIX}${href}` : null} {...otherProps}>{props.children}</Link>
    )
}

