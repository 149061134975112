import Input from "../general/input"
import Textarea from "../general/textarea"
import FormCard from "./formCard"
import { AddressInputs, ContactInputs } from "./inputCollections"
import { useForm } from "react-hook-form";
import { BirthdayInput } from "./inputs";
import RadioInput from "../general/radioInput";

const FillPrescriptionForm = ({ className }) => {

    const { register, watch, formState: { errors }, handleSubmit } = useForm();

//     const HowToGetMedicationInputs = () => {

//         const RADIO_LABEL = "How do you want to get your medication?"
//         const RADIO_LABELS = ['Pick up', 'By mail']

//         return (
//             <>
            
//                 <RadioInput
//                     label={RADIO_LABEL}
//                     labels={RADIO_LABELS}
//                     name="how-to-get-medication"
//                     required
//                     register={register}
//                     requirements={{

//                     }}
//                     errors={errors}
//                 />
// {/* 
//                 {watch()[RADIO_LABEL] == RADIO_LABELS[0] &&
//                     <AddressInputs errors={errors} register={register} />
//                 } */}

//             </>
//         )
//     }

    return (
        <FormCard buttonTitle="Refill Prescription" handleSubmit={handleSubmit} className={className}>

            <ContactInputs errors={errors} register={register} />

            <BirthdayInput errors={errors} register={register} />
            
            <Textarea
                label="Prescription numbers"
                name="prescription-numbers"
                required
                register={register}
                requirements={{
                    maxLength: 500,
                }}
                placeholder="1263273, 1863828, etc."
                small
                errors={errors}
            />

            {/* <HowToGetMedicationInputs /> */}

            <Textarea
                label="Pick up or send by mail? If by mail, please provide address"
                name="additional-information"
                required
                register={register}
                requirements={{
                    maxLength: 1000,
                }}
                // placeholder="If by mail, please provide address."
                errors={errors}
            />

            <Textarea
                label="Do you have a new insurance to bill?"
                name="new-insurance"
                register={register}
                requirements={{
                    maxLength: 500,
                }}
                errors={errors}
                small
            />

            <Textarea
                label="Additional information"
                name="additional-information"
                register={register}
                requirements={{
                    maxLength: 1000,
                }}
                placeholder="Anything else we should know?"
                errors={errors}
            />

        </FormCard>
    )
}

export default FillPrescriptionForm

