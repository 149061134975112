import { Link } from "preact-router/match"
import { MyLink } from "../navigation/myLink"

const FooterLinks = ({ links, className }) => (
    <nav className={`${className} flex flex-row p-2 px-4 items-center justify-center space-x-8 flex-wrap`}>
        {links.map(link => (
            <MyLink className='hover:underline' href={link.url}>{link.text}</MyLink>
        ))}
    </nav>
)

export default FooterLinks

