import business from "../../../business"
import InfoCard from "../general/infoCard"

const WaitTimeCard = ({ className, waitTime }) => (
    <InfoCard title="How long will this take?" className={`${className} text-center`}>
        {waitTime ? waitTime : business.formWaitTime}
    </InfoCard>
)

export default WaitTimeCard

