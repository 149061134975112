const business = {
    name: `West Marin Pharmacy`,
    phoneNumber: `(415) 663-1121`,
    faxNumber: `(415) 663-1219`,
    address: `
11 4th St
Point Reyes Station, CA
94956
    `,
    hours: [
        {
            day: `Monday`,
            time: `9:00 AM - 6:00 PM`,
        },
        {
            day: `Tuesday`,
            time: `9:00 AM - 6:00 PM`,
        },
        {
            day: `Wednesday`,
            time: `9:00 AM - 6:00 PM`,
        },
        {
            day: `Thursday`,
            time: `9:00 AM - 6:00 PM`,
        },
        {
            day: `Friday`,
            time: `9:00 AM - 6:00 PM`,
        },
        {
            day: `Saturday`,
            time: `9:00 AM - 4:00 PM`,
            // note: `(prescription pickup 10-4 only)`,
        },
        {
            day: `Sunday`,
            time: `10:00 AM - 2:00 PM`,
            note: `(no prescription pickup)`,
        },
    ],
    // hoursNote: `On Sundays, no pharmacist on duty, no prescription pickup.`,
    googleMapsLink: `https://goo.gl/maps/1NgJmWmN4ZVeRFhJ9`,
    email: `zsu@wm-rx.com`,
    links: [
        {
            text: `Contact Us`,
            url: `/contact-us`,
        },
        {
            text: `About Us`,
            url: `/about-us`,
        },
        {
            text: `My Pharmacy`,
            url: `/my-pharmacy`,
        },
        // {
        //     text: `Our Team`,
        //     url: `/our-team`,
        // },
        {
            text: `Refill Prescription`,
            url: `/refill-prescription`,
        },
        {
            text: `Services`,
            url: `/services`,
        },
    ],
    facebookLink: `https://www.facebook.com/WestMarinPharmacy/`,
    formWaitTime: `You will get a reply back via text within 48 hours.`,
    interactiveMapLink: `https://www.google.com/maps/embed?pb=!1m22!1m8!1m3!1d3141.06428673594!2d-122.80859803467382!3d38.06888392970695!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d38.0686402!2d-122.805892!4m5!1s0x8085c6803b61d289%3A0xc7e300f6d02bbf09!2swest%20marin%20pharmacy!3m2!1d38.0688253!2d-122.8070731!5e0!3m2!1sen!2sus!4v1654913854522!5m2!1sen!2sus`,
    backgroundImage: `https://www.artranked.com/images/17/172d5da7ac85b34b80e4d8d533acf4ff.jpeg`,
}

export default business

