import { h } from 'preact';
import { Link } from 'preact-router/match';
import business from '../../../business.js'
import info from './info.js';
import HeaderLinks from './headerLink.js';
import HeaderLinksMobile from './headerLinksMobile.js';
import { MyLink } from '../navigation/myLink.js';
import Divider from '../general/divider.js';

const Header = () => (
	<header>
		<nav className='flex flex-row p-2 px-4 items-center bg-white shadow-lg'>

			<MyLink className='font-bold hover:underline' href="/">{business.name}</MyLink>

			<div className='grow'></div>

			<HeaderLinks className='hidden lg:block' links={business.links} />
			<HeaderLinksMobile className='block lg:hidden' links={business.links} />
			
		</nav>
		{/* <Divider /> */}
	</header>
);

export default Header;
